<script>
import Vue from 'vue'
import { EventBus } from './event-bus'
// This imports <b-card> along with all the <b-card-*> sub-components as a plugin:
import { CardPlugin } from 'bootstrap-vue'
Vue.use(CardPlugin)

import { global } from './utils'
export default {
  name: 'Events',
  props: {
    user: [Object, String],
    campaignsProp: Array,
    categoriesProp: Array,
    language: String,
  },
  data() {
    return {
      global,
      errors: {},
      loadings: {},
      event: {},
      events: [],
      searchTerm: '',
      activeFilters: {
        categories: [],
        tags: [],
        date: {
          start: '',
          end: '',
        },
      },
      categories: [],
      showCustomDateFilter: false,
      showFilters: false,
      showCategoryFilters: false,
      showDateFilters: false,
      showMoreCategories: false,
    }
  },
  methods: {
    setError(name) {
      this.$set(this.errors, name, true)
    },
    clearError(name) {
      this.$set(this.errors, name, false)
    },
    setLoading(name) {
      this.$set(this.loadings, name, true)
    },
    clearLoading(name) {
      this.$set(this.loadings, name, false)
    },
    getCategoriesLabels(categories) {
      return categories.map((category) => category.label.toLowerCase())
    },
    getCategoriesIds(categories) {
      return categories.map((category) => category.id)
    },
    getTags(meta) {
      return (meta && meta.tags) || []
    },
    getStartDate(meta) {
      return meta && meta.date && meta.date.start
    },
    getEndDate(meta) {
      return meta && meta.date && meta.date.end
    },
    getHour(meta) {
      return meta && meta.date && meta.date.hour
    },
    getLocationText(meta) {
      return (
        (meta &&
          meta.location &&
          meta.location.text &&
          meta.location.text.toLowerCase()) ||
        ''
      )
    },
    getDateText(meta) {
      return this.getStartDate(meta)
        ? this.formatDateFromString(this.getStartDate(meta), this.localeCode)
        : 'permanent exhibition' + ' ' + this.getEndDate(meta)
        ? this.formatDateFromString(this.getEndDate(meta), this.localeCode)
        : 'permanent exhibition' + ' ' + this.getHour(meta)
        ? this.getHour(meta)
        : ''
    },
    getCategoriesEndpoint() {
      this.setLoading('getCategoriesEndpoint')
      this.clearError('getCategoriesEndpoint')
      this.axios
        .get(this.$root.apiUrl + '/agitator/campaigns/categories/')
        .then((response) => {
          this.global.debug(response.data)
          this.categories = JSON.parse(JSON.stringify(response.data.categories))
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('getCategoriesEndpoint')
          this.clearLoading('getCategoriesEndpoint')
        })
        .finally(() => this.clearLoading('getCategoriesEndpoint'))
    },
    toggleCategoryFilter(category) {
      if (this.getCategoryFilterActive(category)) {
        this.removeCategoryFilter(category)
      } else {
        this.activeFilters.categories.push(category)
      }
    },
    removeCategoryFilter(category) {
      this.activeFilters.categories = this.activeFilters.categories.filter(
        (filterCategory) => filterCategory.id !== category.id
      )
    },
    setTagFilter(tag) {
      if (
        this.activeFilters.tags.findIndex((filterTag) => filterTag === tag) ===
        -1
      ) {
        this.activeFilters.tags.push(tag)
      }
    },
    removeTagFilter(tag) {
      this.activeFilters.tags = this.activeFilters.tags.filter(
        (filterTag) => filterTag !== tag
      )
    },
    formatDateFromString(dateString, locale) {
      if (dateString) {
        const date = new Date(dateString)
        return new Intl.DateTimeFormat(locale, { dateStyle: 'short' }).format(
          date
        )
      }
    },
    formatHourFromString(hourString, locale) {
      if (hourString) {
        const date = new Date('1970-01-01T' + hourString)
        return new Intl.DateTimeFormat(locale, {
          hour: 'numeric',
          minute: 'numeric',
        }).format(date)
      }
    },
    getCategoryFilterActive(category) {
      return this.activeFilters.categories.includes(category)
    },
    clearFilters() {
      this.activeFilters = {
        categories: [],
        tags: [],
        date: {
          start: '',
          end: '',
        },
      }
      this.showCustomDateFilter = false
    },
    clearFiltersAndSearch() {
      this.activeFilters = {
        categories: [],
        tags: [],
        date: {
          start: '',
          end: '',
        },
      }
      this.showCustomDateFilter = false
      this.clearSearchTerm()
    },
    clearDateFilters() {
      const activeCategoryFilter = this.activeFilters.categories
      const activeTagFilter = this.activeFilters.tags
      this.activeFilters = {
        categories: activeCategoryFilter,
        tags: activeTagFilter,
        date: {
          start: '',
          end: '',
        },
      }
      this.showCustomDateFilter = false
    },
    clearCategoryFilters() {
      const activeDateFilter = this.activeFilters.date
      const activeTagFilter = this.activeFilters.tags
      this.activeFilters = {
        categories: [],
        tags: activeTagFilter,
        date: activeDateFilter,
      }
    },
    getDateFilterActive(rangeObject) {
      return (
        this.activeFilters.date.start.toString() ===
          rangeObject.start.toString() &&
        this.activeFilters.date.end.toString() === rangeObject.end.toString()
      )
    },
    toggleDateFilter(rangeObject) {
      if (!this.getDateFilterActive(rangeObject)) {
        this.activeFilters.date.start = rangeObject.start
        this.activeFilters.date.end = rangeObject.end
      } else {
        this.activeFilters.date.start = ''
        this.activeFilters.date.end = ''
      }
      this.showCustomDateFilter = false
    },
    getDateToday() {
      let now = new Date()
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset())
      const formattedNow = now.toJSON().slice(0, 10)
      return { start: formattedNow, end: formattedNow }
    },
    getDateThisWeek() {
      const now = new Date()
      let weekStart = this.getPastMonday(now)
      let weekEnd = new Date(
        new Date(weekStart).setDate(weekStart.getDate() + 6)
      )
      weekStart.setMinutes(
        weekStart.getMinutes() - weekStart.getTimezoneOffset()
      )
      const formattedWeekStart = weekStart.toJSON().slice(0, 10)
      weekEnd.setMinutes(weekEnd.getMinutes() - weekEnd.getTimezoneOffset())
      const formattedWeekEnd = weekEnd.toJSON().slice(0, 10)
      return { start: formattedWeekStart, end: formattedWeekEnd }
    },
    getDateThisWeekend() {
      const now = new Date()
      const weekendStart = this.getUpcomingSaturday(now)
      const weekendEnd = new Date(
        new Date(weekendStart).setDate(weekendStart.getDate() + 1)
      )
      weekendStart.setMinutes(
        weekendStart.getMinutes() - weekendStart.getTimezoneOffset()
      )
      const formattedWeekendStart = weekendStart.toJSON().slice(0, 10)
      weekendEnd.setMinutes(
        weekendEnd.getMinutes() - weekendEnd.getTimezoneOffset()
      )
      const formattedWeekendEnd = weekendEnd.toJSON().slice(0, 10)
      return { start: formattedWeekendStart, end: formattedWeekendEnd }
    },
    getDateThisMonth() {
      const now = new Date()
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
      const lastDay = new Date(
        now.getFullYear(),
        now.getMonth(),
        this.daysInMonth(now.getMonth() + 1, now.getFullYear())
      )
      firstDay.setMinutes(firstDay.getMinutes() - firstDay.getTimezoneOffset())
      const formattedfirstDay = firstDay.toJSON().slice(0, 10)
      lastDay.setMinutes(lastDay.getMinutes() - lastDay.getTimezoneOffset())
      const formattedLastDay = lastDay.toJSON().slice(0, 10)
      return { start: formattedfirstDay, end: formattedLastDay }
    },
    getPastMonday(date) {
      const d = new Date(date)
      const day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
      return new Date(d.setDate(diff))
    },
    getUpcomingSaturday(date) {
      const d = new Date(date)
      const day = d.getDay(),
        diff = d.getDate() - day + (day === 6 ? 0 : 6) // adjust when day is saturday
      return new Date(d.setDate(diff))
    },
    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate()
    },
    setSearchTerm(searchTerm) {
      this.searchTerm = searchTerm
    },
    clearSearchTerm() {
      this.searchTerm = ''
      EventBus.$emit('event-search-term-clear')
    },
  },
  computed: {
    localeCode() {
      // if (this.language.toLowerCase() === 'en') {
      //   return 'en-US'
      // }
      // if (this.language.toLowerCase() === 'is') {
      //   return 'is' // NOTE: will not work in Chrome, icelandic locale is not supported
      // }
      // default fallback
      return 'en-GB'
    },
    authHeader() {
      return this.user && this.user.token && 'Bearer ' + this.user.token
    },
    searchedEvents() {
      if (this.searchTerm !== '') {
        const searchTerms = this.searchTerm.split(' ')
        // eslint-disable-next-line no-unused-vars
        return this.filteredEvents.filter((event) => {
          return searchTerms.some((term) => {
            if (term !== '') {
              return (
                event.title.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
                event.long_description
                  .toLowerCase()
                  .indexOf(term.toLowerCase()) !== -1 ||
                this.getCategoriesLabels(event.categories).some(
                  (label) =>
                    label.toLowerCase().indexOf(term.toLowerCase()) !== -1
                ) ||
                this.getLocationText(event.meta).indexOf(term.toLowerCase()) !==
                  -1 ||
                (this.getDateText(event.meta)
                  ? this.getDateText(event.meta).indexOf(term.toLowerCase()) !==
                    -1
                  : 'permanent exhibition'.indexOf(term.toLowerCase()) !== -1)
              )
            }
          })
        })
      } else {
        return this.filteredEvents
      }
    },
    filteredEvents() {
      return this.events.filter((event) => {
        if (this.activeFilters.categories.length !== 0) {
          return event.categories.some((category) =>
            this.getCategoriesIds(this.activeFilters.categories).includes(
              category.id
            )
          )
        }
        if (this.activeFilters.tags.length !== 0) {
          return (
            this.getTags(event.meta).filter((value) =>
              this.activeFilters.tags.includes(value)
            ).length > 0
          )
        }
        if (this.activeFilters.date.start && this.activeFilters.date.end) {
          return (
            (new Date(this.getStartDate(event.meta)) <=
              new Date(this.activeFilters.date.start) &&
              new Date(this.activeFilters.date.start) <=
                new Date(this.getEndDate(event.meta))) ||
            (new Date(this.getEndDate(event.meta)) <=
              new Date(this.activeFilters.date.end) &&
              new Date(this.activeFilters.date.end) <=
                new Date(this.getEndDate(event.meta))) ||
            (new Date(this.activeFilters.date.start) <
              new Date(this.getStartDate(event.meta)) &&
              new Date(this.getEndDate(event.meta)) <
                new Date(this.activeFilters.date.end)) ||
            (!this.getStartDate(event.meta) && !this.getEndDate(event.meta))
          )
        }
        return true
      })
    },
    hasActiveFilters() {
      return (
        this.activeFilters.categories.length > 0 ||
        this.activeFilters.tags.length > 0 ||
        (this.activeFilters.date.start && this.activeFilters.date.end)
      )
    },
    isDateFilterActive() {
      return this.activeFilters.date.start && this.activeFilters.date.end
    },
    isCategoryFilterActive() {
      return this.activeFilters.categories.length !== 0
    },
    eventsTags() {
      return this.events.flatMap((event) => {
        return this.getTags(event.meta)
      })
    },
    availableCategories() {
      const usedCategories = this.events.flatMap((event) => {
        return event.categories
      })
      return usedCategories.filter(
        (v, i, a) =>
          a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
      )
    },
  },
  mounted() {
    this.events = JSON.parse(JSON.stringify(this.campaignsProp))
    this.categories = JSON.parse(JSON.stringify(this.categoriesProp))
  },
  created() {
    EventBus.$on('event-search-term', this.setSearchTerm)
  },
}
</script>

<style scoped lang="scss">
.no-link-style {
  text-decoration: none;
}
.filter-button.active {
  border: 1px solid #2e2e2e !important;
  color: inherit !important;
  background-color: inherit !important;
}
</style>

<style lang="scss">
.filter-date-dropdown .form-control {
  width: 100%;
}
</style>
